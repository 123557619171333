import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getWorkflowRequestApprovalInfo } from '@selectors/instantAccess'
import { submitWorkflowApprovalRequest } from '@actions/'
import { getInstantAccessToken } from '@shared/utils'

const mapStateToProps = (state, ownProps) => {
  const { idOrg, id, secret, response, idActionExe } = ownProps
  const { submitSuccess, authError, loading = true, alreadyCompleted, approveText, declineText } = getWorkflowRequestApprovalInfo(state)

  return {
    idOrg,
    idActionExe,
    approved: Boolean(response === 'approve'),
    token: getInstantAccessToken(id, secret),
    isAuthError: Boolean(authError),
    alreadyCompleted,
    submitSuccess,
    approveText,
    declineText,
    loading
  }
}

const mapDispatchToProps = {
  submitWorkflowApprovalRequest
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
