import React, { ReactElement } from 'react'
import { Styles } from '@components/appAboutBox/appCompliance/styles'
import { Stack, Icon, Link, Spacer, Tooltip, Subtitle2 } from '@toriihq/design-system'
import get from 'lodash/get'
import { COMPLIANCE_TYPE_LABELS, COMPLIANCE_TYPES } from '@components/appAboutBox/appCompliance/constants'
import { AppComplianceData } from '@components/appAboutBox/appCompliance/types'
import { PlanNameTooltip } from '@components/planNameTooltip'
import Analytics from '@helpers/analytics'

const ComplianceItem = ({ label, isCompliant }: { label: string, isCompliant: boolean }): ReactElement => {
  return <Styles.ComplianceItem>
    <Spacer right={'space-100'}><Icon name={isCompliant ? 'CheckCircle' : 'CircleMinus'} color={isCompliant ? 'interactive' : 'disabled'} /></Spacer>
    <Styles.Label isCompliant={isCompliant} >{label}</Styles.Label>
  </Styles.ComplianceItem>
}

const ComplianceItemLink = ({ label, isCompliant, link }: { label: string, isCompliant: boolean, link: string }): ReactElement => {
  const onClick = () => {
    Analytics.track('Click on compliance reference field', { 'field name': label })
  }

  return <Link href={link} target='_blank' disabled={!isCompliant} onClick={onClick}>
    <ComplianceItem label={label} isCompliant={isCompliant} />
  </Link>
}

const FailedComplianceItem = ({ label, isCompliant }: { label: string, isCompliant: boolean }): ReactElement => {
  return <Tooltip
    placement='top'
    label='No evidence found'>
    <ComplianceItem label={label} isCompliant={isCompliant} />
  </Tooltip>
}

const RenderComplianceItem = ({ label, isCompliant, link }: { label: string, isCompliant: boolean, link: string }): ReactElement => {
  return (isCompliant && link) ? <ComplianceItemLink label={label} isCompliant={isCompliant} link={link} /> : <FailedComplianceItem label={label} isCompliant={isCompliant} />
}

const ComplianceHeader = ({ isAppComplianceEnabledInPlan }: {isAppComplianceEnabledInPlan: boolean}): ReactElement => {
  return <Styles.ComplianceSectionHeader>
    <Spacer right={'space-100'}>
      <Icon name='FileCertificate' color='secondary' />
    </Spacer>
    <Styles.ComplianceHeaderText><Subtitle2>CERTIFICATIONS & COMPLIANCE</Subtitle2></Styles.ComplianceHeaderText>
    {!isAppComplianceEnabledInPlan && <PlanNameTooltip planName='Enterprise' />}
  </Styles.ComplianceSectionHeader>
}

export const AppCompliance = ({ data, appName, loggedInUserEmail, isAppComplianceEnabledInPlan }: { data: AppComplianceData, appName: string, loggedInUserEmail: string, isAppComplianceEnabledInPlan: boolean }): ReactElement => {
  return <div>
    <ComplianceHeader isAppComplianceEnabledInPlan={isAppComplianceEnabledInPlan} />
    {isAppComplianceEnabledInPlan &&
    <Stack gap='space-200'>
      <Styles.ComplianceItems>
        {
          Object.values(COMPLIANCE_TYPES).map(type => {
            return <RenderComplianceItem label={COMPLIANCE_TYPE_LABELS[type]} isCompliant={get(data, [type, 'compliant'], false)} link={get(data, [type, 'reference'], '')} />
          })
        }
      </Styles.ComplianceItems>
      <div>
        <span>This information is based on publicly available data. Know something we don't? </span>
        <Link
          href={`https://airtable.com/appFSLx6FDKqWRALI/pagbvsbw8JI3Kzvh8/form?prefill_Requested+by=${loggedInUserEmail ?? ''}&prefill_App%20name=${appName ?? ''}`}
          target='_blank'>
          Share with us
        </Link>
      </div>
    </Stack>
    }
  </div>
}
