import send from '@shared/redux-fetch'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'
import {
  GET_CANCELED_TASKS,
  GET_COMPLETED_TASKS,
  GET_OPEN_TASKS,
  GET_OPEN_TASKS_AMOUNT,
  GET_OPEN_TASKS_FILTER_OPTIONS,
  GET_OPEN_TASKS_FIELD_VALUES,
  GET_COMPLETED_TASKS_FILTER_OPTIONS,
  GET_COMPLETED_TASKS_FIELD_VALUES,
  GET_CANCELED_TASKS_FILTER_OPTIONS,
  GET_CANCELED_TASKS_FIELD_VALUES,
  SUBMIT_TASK_APPROVAL_REQUEST
} from '@root/constants'
import { GetTasksFieldValuesProps, GetTasksProps, InternalGetTasksFilterOptionsProps, InternalGetTasksProps, SubmitTaskApprovalRequestProps } from './types'
import { ops } from '@lenses/filters'
import { Filter } from '../types'
import { IdOrg } from '@store/types'

const anyOfOpenStatusesFilter: Filter = { key: 'status', op: ops.anyOf, value: ['inProgress', 'needsAttention'] }
const isCompletedStatusFilter: Filter = { key: 'status', op: ops.equals, value: 'completed' }
const isCanceledStatusFilter: Filter = { key: 'status', op: ops.equals, value: 'canceled' }

export const getOpenTasksAmount = ({ idOrg }: { idOrg: IdOrg }) => {
  const query = {
    filters: JSON.stringify([anyOfOpenStatusesFilter]),
    withoutContent: true
  } as Record<string, string | number | boolean>

  return send(GET_OPEN_TASKS_AMOUNT, {
    url: `/api/orgs/${idOrg}/tasks`,
    query,
    meta: {
      reset: false
    }
  })
}

export const getOpenTasks = ({
  idOrg,
  limit,
  offset,
  sort,
  q,
  reset,
  filters = [],
  withoutContent
}: GetTasksProps) => {
  const openTasksFilters = filters.concat([anyOfOpenStatusesFilter])

  return getTasks({
    actionTarget: GET_OPEN_TASKS,
    idOrg,
    limit,
    offset,
    fields: ['description', 'assignees', 'status', 'statusLabel', 'dueDate', 'typeLabel', 'serialNumber'],
    sort,
    q,
    qFields: ['description', 'assignees.user.fullName', 'statusLabel', 'typeLabel'],
    reset,
    filters: openTasksFilters,
    withoutContent
  })
}

export const getOpenTasksFilterOptions = ({ idOrg }: { idOrg: IdOrg }) => {
  return getTasksFilterOptions({
    idOrg,
    actionTarget: GET_OPEN_TASKS_FILTER_OPTIONS,
    view: 'openTasks'
  })
}

export const getOpenTasksFieldValues = ({
  idOrg,
  fields
}: GetTasksFieldValuesProps) => {
  return send(GET_OPEN_TASKS_FIELD_VALUES, {
    url: `/api/orgs/${idOrg}/tasks/fieldValues`,
    query: {
      fields: JSON.stringify(fields),
      filters: JSON.stringify([anyOfOpenStatusesFilter])
    }
  })
}

export const getCompletedTasks = ({
  idOrg,
  limit,
  offset,
  sort,
  q,
  reset,
  filters = [],
  withoutContent
}: GetTasksProps) => {
  const completedTasksFilters = filters.concat([isCompletedStatusFilter])

  return getTasks({
    actionTarget: GET_COMPLETED_TASKS,
    idOrg,
    limit,
    offset,
    fields: ['description', 'completedBy', 'typeLabel', 'completedAt', 'serialNumber'],
    qFields: ['description', 'completedBy.fullName', 'typeLabel'],
    sort,
    q,
    reset,
    filters: completedTasksFilters,
    withoutContent
  })
}

export const getCompletedTasksFilterOptions = ({ idOrg }: { idOrg: IdOrg }) => {
  return getTasksFilterOptions({
    idOrg,
    actionTarget: GET_COMPLETED_TASKS_FILTER_OPTIONS,
    view: 'completedTasks'
  })
}

export const getCompletedTasksFieldValues = ({
  idOrg,
  fields
}: GetTasksFieldValuesProps) => {
  return send(GET_COMPLETED_TASKS_FIELD_VALUES, {
    url: `/api/orgs/${idOrg}/tasks/fieldValues`,
    query: {
      fields: JSON.stringify(fields),
      filters: JSON.stringify([isCompletedStatusFilter])
    }
  })
}

export const getCanceledTasks = ({
  idOrg,
  limit,
  offset,
  sort,
  q,
  reset,
  filters = [],
  withoutContent
}: GetTasksProps) => {
  const canceledTasksFilters = filters.concat([isCanceledStatusFilter])

  return getTasks({
    actionTarget: GET_CANCELED_TASKS,
    idOrg,
    limit,
    offset,
    fields: ['description', 'assignees', 'typeLabel', 'serialNumber'],
    qFields: ['description', 'assignees.user.fullName', 'typeLabel'],
    sort,
    q,
    reset,
    filters: canceledTasksFilters,
    withoutContent
  })
}

export const getCanceledTasksFilterOptions = ({ idOrg }: { idOrg: IdOrg }) => {
  return getTasksFilterOptions({
    idOrg,
    actionTarget: GET_CANCELED_TASKS_FILTER_OPTIONS,
    view: 'canceledTasks'
  })
}

export const getCanceledTasksFieldValues = ({
  idOrg,
  fields
}: GetTasksFieldValuesProps) => {
  return send(GET_CANCELED_TASKS_FIELD_VALUES, {
    url: `/api/orgs/${idOrg}/tasks/fieldValues`,
    query: {
      fields: JSON.stringify(fields),
      filters: JSON.stringify([isCanceledStatusFilter])
    }
  })
}

const getTasks = ({
  actionTarget,
  idOrg,
  limit = 5, // TODO-OM define default limit
  offset = 0,
  fields,
  sort,
  q,
  qFields = ['description'],
  reset = false,
  filters = [],
  withoutContent = false
}: InternalGetTasksProps) => {
  const query = omitBy({
    limit,
    offset,
    sort,
    q,
    qFields: qFields?.join(','),
    fields: fields.join(','),
    filters: JSON.stringify(filters),
    withoutContent
  }, isUndefined) as Record<string, string | number | boolean>

  return send(actionTarget, {
    url: `/api/orgs/${idOrg}/tasks`,
    query,
    meta: {
      reset
    }
  })
}

const getTasksFilterOptions = (
  { idOrg, actionTarget, view }: InternalGetTasksFilterOptionsProps) => {
  return send(actionTarget, {
    url: `/api/orgs/${idOrg}/tasks/metadata`,
    query: { view }
  })
}

export const submitTaskApprovalRequest = ({
  idOrg,
  idTask,
  token,
  approved
}: SubmitTaskApprovalRequestProps) => {
  return send(SUBMIT_TASK_APPROVAL_REQUEST, {
    url: `/api/orgs/${idOrg}/tasks/${idTask}/complete/approvalRequest`,
    method: 'POST',
    query: {
      token
    },
    body: JSON.stringify({
      approved
    })
  })
}
