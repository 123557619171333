import { METRIC_FUNC, AGGREGATION_SORT_ORDERS, WidgetGroupBySort } from '@root/store/reducers/dashboards/types'
import { DATE_FIELD_TYPES } from '../../shared'
import { useDispatch } from 'react-redux'
import { filterTable } from '@store/actions'
import { Filter } from '@shared/filters/types'
import { DynamicWidget } from '@reducers/dashboards/types'
import { FieldUpdateFuncProps } from '@components/dashboard/widgets/types'
import { cloneDeep, set } from 'lodash'

export const updateGeneralFieldByPath = ({ widget, fieldValue, valuePathInWidget }: { widget: DynamicWidget, fieldValue: any, valuePathInWidget: string }): DynamicWidget => {
  const updatedWidget = cloneDeep(widget)

  set(updatedWidget, valuePathInWidget, fieldValue)

  return updatedWidget
}

export const useUpdateFilters = (widgetTableKey: string) => {
  const dispatch = useDispatch()

  return ({ widget, filters }: { widget: DynamicWidget, filters: Filter[] }): DynamicWidget => {
    dispatch(filterTable({ tableKey: widgetTableKey, filters, supportViews: false, shareViewPreferences: false }))

    return {
      ...widget,
      dataConfig: {
        ...widget.dataConfig,
        filters
      }
    }
  }
}

export const updateGroupByField = ({ widget, fieldValue, entityFields, fieldsDefaultValue }: FieldUpdateFuncProps): DynamicWidget => {
  const field = entityFields?.find((f) => f.value === fieldValue)

  if (!field) {
    return widget
  }

  const newField = { systemKey: fieldValue, type: field.type, label: field.label }

  const newGroupBy = {
    ...widget.displayConfig?.groupBy,
    field: newField
  }

  if (DATE_FIELD_TYPES.includes(newField.type)) {
    newGroupBy.datePeriod = widget.displayConfig?.groupBy?.datePeriod || fieldsDefaultValue?.datePeriod
  } else {
    newGroupBy.datePeriod = undefined
  }

  if (widget.displayConfig?.groupBy?.sort) {
    const { field: currentSortField, aggFunc: currentSortAggFunc } = widget.displayConfig.groupBy.sort

    // when the groupBy field changes and sorting is based on it (meaning there is not aggFunc on this sort)
    // we need to update the sort field to the new groupBy field
    if (currentSortField && widget.displayConfig.groupBy.field.systemKey === currentSortField && !currentSortAggFunc) {
      newGroupBy.sort = updateSortField({ sort: newGroupBy.sort, field: fieldValue })
    }
  }

  return {
    ...widget,
    displayConfig: {
      ...widget.displayConfig,
      groupBy: newGroupBy as DynamicWidget['displayConfig']['groupBy']
    }
  }
}

export const updateMetricFunc = ({ widget, fieldValue, entityFields, fieldsDefaultValue }: FieldUpdateFuncProps): DynamicWidget => {
  const existingMetricFunc = widget.displayConfig?.metric?.func
  const existingMetricField = widget.displayConfig?.metric?.field

  const newMetricConfig = {
    func: fieldValue,
    field: existingMetricField
  }

  if (fieldValue === METRIC_FUNC.TOTAL) {
    newMetricConfig.field = undefined
  } else if (existingMetricFunc !== fieldValue) {
    if (!existingMetricField?.systemKey) {
      const field = entityFields?.find((f) => f.value === fieldsDefaultValue?.metricField)
      if (field) {
        newMetricConfig.field = { systemKey: fieldsDefaultValue?.metricField, type: field.type, label: field.label }
      }
    }
  }

  return {
    ...widget,
    displayConfig: {
      ...widget.displayConfig,
      metric: newMetricConfig
    }
  }
}

export const updateGroupByMetricFunc = ({ widget, fieldValue, entityFields, fieldsDefaultValue }: FieldUpdateFuncProps): DynamicWidget => {
  if (!widget.displayConfig?.groupBy) {
    return widget
  }

  const existingMetricFunc = widget.displayConfig?.groupBy?.metric?.func
  const existingMetricField = widget.displayConfig?.groupBy?.metric?.field

  const newMetricConfig = {
    func: fieldValue,
    field: existingMetricField
  }

  if (fieldValue === METRIC_FUNC.TOTAL) {
    newMetricConfig.field = undefined
  } else if (existingMetricFunc !== fieldValue) {
    if (!existingMetricField?.systemKey) {
      const field = entityFields?.find((f) => f.value === fieldsDefaultValue?.metricField)
      if (field) {
        newMetricConfig.field = { systemKey: fieldsDefaultValue?.metricField, type: field.type, label: field.label }
      }
    }
  }

  const newGroupBy = {
    ...widget.displayConfig.groupBy,
    metric: newMetricConfig
  }

  if (widget.displayConfig?.groupBy?.sort) {
    const { aggFunc: currentSortAggFunc } = widget.displayConfig.groupBy.sort
    if (currentSortAggFunc) {
      // update the aggFunc to match the new metric function
      newGroupBy.sort = updateSortAggFunc({ sort: newGroupBy.sort, aggFunc: fieldValue })

      if (currentSortAggFunc === METRIC_FUNC.TOTAL && fieldValue !== METRIC_FUNC.TOTAL) {
        // when the metric function changes and sorting is based on the metric field, we need to update the sort field to the new metric field
        newGroupBy.sort = updateSortField({ sort: newGroupBy.sort, field: newMetricConfig.field?.systemKey })
      } else if (currentSortAggFunc !== METRIC_FUNC.TOTAL && fieldValue === METRIC_FUNC.TOTAL) {
        // when the metric function changes and sorting is based on the metric field but the new metric function is TOTAL, we need to remove the sort field
        newGroupBy.sort = updateSortField({ sort: newGroupBy.sort, field: undefined })
      }
    }
  }

  return {
    ...widget,
    displayConfig: {
      ...widget.displayConfig,
      groupBy: newGroupBy
    }
  }
}

export const updateMetricField = ({ widget, fieldValue, entityFields }: FieldUpdateFuncProps): DynamicWidget => {
  const field = entityFields?.find((f) => f.value === fieldValue)

  if (!field || !widget.displayConfig.metric?.func || widget.displayConfig.metric.func === METRIC_FUNC.TOTAL) {
    return widget
  }

  const newMetricField = { systemKey: fieldValue, type: field.type, label: field.label }

  return {
    ...widget,
    displayConfig: {
      ...widget.displayConfig,
      metric: {
        func: widget.displayConfig.metric.func,
        field: newMetricField
      }
    }
  }
}

export const updateGroupByMetricField = ({ widget, fieldValue, entityFields }: FieldUpdateFuncProps): DynamicWidget => {
  const field = entityFields?.find((f) => f.value === fieldValue)

  if (!field || !widget.displayConfig?.groupBy?.metric?.func || widget.displayConfig?.groupBy?.metric?.func === METRIC_FUNC.TOTAL) {
    return widget
  }

  const newMetricField = { systemKey: fieldValue, type: field.type, label: field.label }

  const newGroupBy = {
    ...widget.displayConfig.groupBy,
    metric: {
      func: widget.displayConfig.groupBy?.metric?.func,
      field: newMetricField
    }
  }

  if (widget.displayConfig?.groupBy?.sort) {
    const { field: currentSortField, aggFunc: currentSortAggFunc } = widget.displayConfig.groupBy.sort
    // when the metric field changes and sorting is based on it, we need to update the sort field to the new metric field
    if (currentSortField && widget.displayConfig.groupBy?.metric?.field?.systemKey === currentSortField && currentSortAggFunc) {
      newGroupBy.sort = updateSortField({ sort: newGroupBy.sort, field: fieldValue })
    }
  }

  return {
    ...widget,
    displayConfig: {
      ...widget.displayConfig,
      metric: undefined,
      groupBy: newGroupBy
    }
  }
}

export const updateNumberOfGroups = ({ widget, fieldValue }: FieldUpdateFuncProps): DynamicWidget => { // TODO: maybe this could be updated by the general updateGroupByField function
  if (!widget.displayConfig?.groupBy) {
    return widget
  }

  return {
    ...widget,
    displayConfig: {
      ...widget.displayConfig,
      groupBy: {
        ...widget.displayConfig.groupBy,
        size: Number(fieldValue)
      }
    }
  }
}

export const updateTableSortField = ({ widget, fieldValue }: FieldUpdateFuncProps): DynamicWidget => {
  const order = widget.displayConfig?.sort?.[0]?.split(':')?.[1]
  const newSort = [`${fieldValue}:${order}`]

  return {
    ...widget,
    displayConfig: {
      ...widget.displayConfig,
      sort: newSort
    }
  }
}

export const updateTableSortOrder = ({ widget, fieldValue }: FieldUpdateFuncProps): DynamicWidget => {
  const field = widget.displayConfig?.sort?.[0]?.split(':')?.[0]
  const newSort = [`${field}:${fieldValue}`]

  return {
    ...widget,
    displayConfig: {
      ...widget.displayConfig,
      sort: newSort
    }
  }
}

export const updateGroupBySortField = ({ widget, fieldValue }: FieldUpdateFuncProps): DynamicWidget => {
  if (!widget.displayConfig?.groupBy) {
    return widget
  }

  const [updatedFieldValue, aggFunc] = fieldValue.split(':')
  const newSort = {
    ...widget.displayConfig.groupBy.sort,
    field: aggFunc === METRIC_FUNC.TOTAL ? undefined : updatedFieldValue,
    aggFunc: aggFunc ? widget.displayConfig.groupBy.metric.func : undefined
  }

  return {
    ...widget,
    displayConfig: {
      ...widget.displayConfig,
      groupBy: {
        ...widget.displayConfig.groupBy,
        sort: newSort
      }
    }
  }
}

export const updateGroupBySortOrder = ({ widget, fieldValue }: FieldUpdateFuncProps): DynamicWidget => {
  if (!widget.displayConfig?.groupBy) {
    return widget
  }

  const newSort = updateSortOrder({ sort: widget.displayConfig.groupBy.sort, order: fieldValue })

  const newGroupBy = {
    ...widget.displayConfig.groupBy,
    sort: newSort
  }

  return {
    ...widget,
    displayConfig: {
      ...widget.displayConfig,
      groupBy: newGroupBy
    }
  }
}

const updateSortField = ({ sort, field }: { sort: WidgetGroupBySort | undefined, field: string | undefined }): WidgetGroupBySort => {
  return {
    ...sort,
    field
  } as WidgetGroupBySort
}

const updateSortAggFunc = ({ sort, aggFunc }: { sort: WidgetGroupBySort | undefined, aggFunc: METRIC_FUNC }): WidgetGroupBySort => {
  return {
    ...sort,
    aggFunc
  } as WidgetGroupBySort
}

const updateSortOrder = ({ sort, order }: { sort: WidgetGroupBySort | undefined, order: AGGREGATION_SORT_ORDERS }): WidgetGroupBySort => {
  return {
    ...sort,
    order
  } as WidgetGroupBySort
}
