import React from 'react'
import PropTypes from 'prop-types'
import PieChart from './pieChart'
import { Tooltip, Icon, Subtitle2, Link } from '@toriihq/design-system'
import RelativeTeamLink from '../relativeTeamLink'
import * as Style from './style'

const PieChartBox = ({ chartsInfo, header, loading, customTooltip, description, link }) => {
  return (
    <Style.Container>
      {!loading && header && <Style.HeaderContainer>
        <Style.Header><Subtitle2>{header}</Subtitle2></Style.Header>
        {description && <Tooltip
          placement='top'
          label={description}>
          <Icon name='Info' />
        </Tooltip>}
        {link && <Style.StyledLink>
          <RelativeTeamLink to={link.path}>
            <Link>{link.text}</Link>
          </RelativeTeamLink></Style.StyledLink>}
      </Style.HeaderContainer>}
      <Style.ChartsContainer>
        {chartsInfo.map((chart, index) => <PieChart key={index} data={chart.data} hasData={chart.hasData} header={chart.header} loading={loading} customTooltip={customTooltip} overrideStyle={{ '> div:first-child': { flex: 1, '@media(max-width: 300px)': { flex: `0 0 100%`, marginTop: '20px' } } }} />)}
      </Style.ChartsContainer>
    </Style.Container>
  )
}

const chartInfo = PropTypes.shape({
  header: PropTypes.string,
  hasData: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.element,
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired
  })),
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired
  })
})

PieChartBox.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
  chartsInfo: PropTypes.arrayOf(chartInfo),
  loading: PropTypes.bool,
  customTooltip: PropTypes.element
}

export default PieChartBox
