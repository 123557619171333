import React from 'react'
import moment from 'moment'
import get from 'lodash/get'
import * as Style from './style'

const Tooltip = ({ payload, active, hideOnZeroValue = false, formatter = value => value, keyValueFormatter = value => value }) => {
  if (!active) {
    return null
  }

  const { date, value: totalAmount, keyValueList = [], tooltipHeader, tooltipSubHeader } = get(payload, ['0', 'payload'], {})

  if (hideOnZeroValue && !totalAmount) {
    return null
  }

  const now = moment.utc().startOf('month')
  const isIncompleteMonth = now.isSame(moment.utc(date).startOf('month'))

  return (
    <Style.TooltipContainer>
      <Style.HeaderContainer>
        <Style.DateContainer>
          <Style.DateText $uppercase>{moment.utc(date).format('MMMM YYYY')}</Style.DateText>
          {isIncompleteMonth && <Style.DateText> (Incomplete month)</Style.DateText>}
        </Style.DateContainer>
        <Style.Header>
          <span>{tooltipHeader}</span>
          <span>{`${formatter(totalAmount)}`}</span>
        </Style.Header>
        <Style.SubHeader>{tooltipSubHeader}</Style.SubHeader>
      </Style.HeaderContainer>
      <Style.KeyValueList>
        {keyValueList.map(({ key, value }) => (
          <Style.KeyValuePair key={key}>
            <span>{key}</span>
            <Style.Value>{keyValueFormatter(value)}</Style.Value>
          </Style.KeyValuePair>
        ))}
      </Style.KeyValueList>
    </Style.TooltipContainer>
  )
}

export default Tooltip
