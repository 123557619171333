import { formFieldTypes } from '@root/constants'
import { WidgetConfig, DropdownOptionsSource } from '@components/dashboard/widgets/types'
import { ops } from '@lenses/filters'
import { DATE_FIELD_TYPES } from '@components/dashboard/widgets/shared'
import { filterGroupByOptions, filterNumericOptions } from '../../shared/widgetConfig/filterOptions'
import { widgetInfoSection } from '../../shared/widgetConfig/constants'
import { DATE_PERIOD, METRIC_FUNC } from '@root/store/reducers/dashboards/types'
import { updateGroupByField, updateGroupByMetricFunc, updateGroupByMetricField, updateNumberOfGroups } from '../../shared/widgetConfig/updateFuncs'

const config: WidgetConfig = {
  editableSections: [
    widgetInfoSection,
    {
      key: 'dataConfig',
      label: 'Data',
      fields: [
        {
          key: 'groupBy',
          label: 'Group by',
          type: formFieldTypes.dropdown,
          dropdownOptionsSource: DropdownOptionsSource.ENTITY_FIELDS,
          filterOptions: filterGroupByOptions,
          valuePathInWidget: 'displayConfig.groupBy.field',
          updateFunc: updateGroupByField
        },
        {
          key: 'datePeriod',
          label: 'Period Selector',
          type: formFieldTypes.dropdown,
          dropdownOptionsSource: DropdownOptionsSource.CLOSED_LIST,
          options: [
            { label: 'Weekly', value: DATE_PERIOD.WEEKLY },
            { label: 'Monthly', value: DATE_PERIOD.MONTHLY },
            { label: 'Quarterly', value: DATE_PERIOD.QUARTERLY },
            { label: 'Yearly', value: DATE_PERIOD.YEARLY }
          ],
          valuePathInWidget: 'displayConfig.groupBy.datePeriod',
          showOnFieldSpecificCondition: { fieldKey: 'groupBy', comparisonType: 'type', value: DATE_FIELD_TYPES, op: ops.anyOf }
        },
        {
          key: 'metricFunc',
          label: 'Aggregation',
          type: formFieldTypes.dropdown,
          dropdownOptionsSource: DropdownOptionsSource.CLOSED_LIST,
          valuePathInWidget: 'displayConfig.groupBy.metric.func',
          options: [
            { label: 'Count', value: METRIC_FUNC.TOTAL },
            { label: 'Summation', value: METRIC_FUNC.SUM }
          ],
          updateFunc: updateGroupByMetricFunc
        },
        {
          key: 'metricField',
          label: 'Field',
          type: formFieldTypes.dropdown,
          dropdownOptionsSource: DropdownOptionsSource.ENTITY_FIELDS,
          filterOptions: filterNumericOptions,
          valuePathInWidget: 'displayConfig.groupBy.metric.field.systemKey',
          showOnFieldSpecificCondition: { fieldKey: 'metricFunc', comparisonType: 'value', value: 'total', op: ops.notEquals },
          updateFunc: updateGroupByMetricField
        },
        {
          key: 'filters',
          label: 'Filter by',
          type: formFieldTypes.filters,
          valuePathInWidget: 'dataConfig.filters'
        }
      ]
    },
    {
      key: 'displayConfig',
      label: 'Appearance',
      fields: [
        {
          key: 'numberOfGroups',
          label: 'Max groups displayed',
          type: formFieldTypes.number,
          valuePathInWidget: 'displayConfig.groupBy.size',
          min: 1,
          max: 100,
          setDefaultIfInvalid: (value: number): number => {
            return (value < 1 || value > 100) ? 5 : value
          },
          updateFunc: updateNumberOfGroups
        }
      ]
    }
  ]
}

export default config
