import { formFieldTypes } from '@root/constants'
import { WidgetConfig } from '@components/dashboard/widgets/types'
import { ops } from '@lenses/filters'
import { DATE_FIELD_TYPES } from '@components/dashboard/widgets/shared'
import {
  AGGREGATION_SORT_ORDERS,
  METRIC_FUNC,
  DynamicWidget,
  BAR_ORIENTATION,
  DATE_PERIOD
} from '@reducers/dashboards/types'
import { filterGroupByOptions, filterNumericOptions, filterSortByOptions } from '../../shared/widgetConfig/filterOptions'
import { formatSortFieldWithAggFunc } from '@root/components/dashboard/dashboardEditWidget/shared'
import { widgetInfoSection } from '../../shared/widgetConfig/constants'
import { DropdownOptionsSource } from '../../types'
import { updateGroupByField, updateGroupByMetricField, updateGroupByMetricFunc, updateGroupBySortField, updateGroupBySortOrder, updateNumberOfGroups } from '../../shared/widgetConfig/updateFuncs'

const config: WidgetConfig = {
  editableSections: [
    widgetInfoSection,
    {
      key: 'dataConfig',
      label: 'Data',
      fields: [
        {
          key: 'groupBy',
          label: 'Group by',
          type: formFieldTypes.dropdown,
          dropdownOptionsSource: DropdownOptionsSource.ENTITY_FIELDS,
          filterOptions: filterGroupByOptions,
          valuePathInWidget: 'displayConfig.groupBy.field',
          updateFunc: updateGroupByField
        },
        {
          key: 'datePeriod',
          label: 'Period Selector',
          type: formFieldTypes.dropdown,
          dropdownOptionsSource: DropdownOptionsSource.CLOSED_LIST,
          options: [
            { label: 'Weekly', value: DATE_PERIOD.WEEKLY },
            { label: 'Monthly', value: DATE_PERIOD.MONTHLY },
            { label: 'Quarterly', value: DATE_PERIOD.QUARTERLY },
            { label: 'Yearly', value: DATE_PERIOD.YEARLY }
          ],
          valuePathInWidget: 'displayConfig.groupBy.datePeriod',
          showOnFieldSpecificCondition: { fieldKey: 'groupBy', comparisonType: 'type', value: DATE_FIELD_TYPES, op: ops.anyOf }
        },
        {
          key: 'metricFunc',
          label: 'Aggregation',
          type: formFieldTypes.dropdown,
          dropdownOptionsSource: DropdownOptionsSource.CLOSED_LIST,
          options: [
            { label: 'Count', value: METRIC_FUNC.TOTAL },
            { label: 'Summation', value: METRIC_FUNC.SUM },
            { label: 'Average', value: METRIC_FUNC.AVG },
            { label: 'Maximum', value: METRIC_FUNC.MAX },
            { label: 'Minimum', value: METRIC_FUNC.MIN }
          ],
          valuePathInWidget: 'displayConfig.groupBy.metric.func',
          updateFunc: updateGroupByMetricFunc
        },
        {
          key: 'metricField',
          label: 'Field',
          type: formFieldTypes.dropdown,
          dropdownOptionsSource: DropdownOptionsSource.ENTITY_FIELDS,
          filterOptions: filterNumericOptions,
          valuePathInWidget: 'displayConfig.groupBy.metric.field.systemKey',
          showOnFieldSpecificCondition: { fieldKey: 'metricFunc', comparisonType: 'value', value: 'total', op: ops.notEquals },
          updateFunc: updateGroupByMetricField
        },
        {
          key: 'sortBy',
          label: 'Sort by',
          type: formFieldTypes.inlineGroup,
          fields: [
            {
              key: 'sortField',
              type: formFieldTypes.dropdown,
              dropdownOptionsSource: DropdownOptionsSource.ENTITY_FIELDS,
              filterOptions: filterSortByOptions,
              getValueFunc: (widget: DynamicWidget) => {
                const sortField = widget.displayConfig?.groupBy?.sort?.field
                const sortAggFunc = widget.displayConfig?.groupBy?.sort?.aggFunc
                return formatSortFieldWithAggFunc(sortField, sortAggFunc)
              },
              updateFunc: updateGroupBySortField
            },
            {
              key: 'sortOrder',
              type: formFieldTypes.buttonIcon,
              dropdownOptionsSource: DropdownOptionsSource.CLOSED_LIST,
              options: [
                { icon: 'SortAscending', value: AGGREGATION_SORT_ORDERS.ASC },
                { icon: 'SortDescending', value: AGGREGATION_SORT_ORDERS.DESC }
              ],
              valuePathInWidget: 'displayConfig.groupBy.sort.order',
              updateFunc: updateGroupBySortOrder
            }
          ]
        },
        {
          key: 'filters',
          label: 'Filter by',
          type: formFieldTypes.filters,
          valuePathInWidget: 'dataConfig.filters'
        }
      ]
    },
    {
      key: 'displayConfig',
      label: 'Appearance',
      fields: [
        {
          key: 'barOrientation',
          label: 'Bar orientation',
          type: formFieldTypes.dropdown,
          dropdownOptionsSource: DropdownOptionsSource.CLOSED_LIST,
          valuePathInWidget: 'displayConfig.barOrientation',
          options: [
            { label: 'Horizontal', value: BAR_ORIENTATION.HORIZONTAL },
            { label: 'Vertical', value: BAR_ORIENTATION.VERTICAL }
          ]
        },
        {
          key: 'numberOfGroups',
          label: 'Max groups displayed',
          type: formFieldTypes.number,
          valuePathInWidget: 'displayConfig.groupBy.size',
          min: 1,
          max: 100,
          setDefaultIfInvalid: (value: number): number => {
            return (value < 1 || value > 100) ? 5 : value
          },
          updateFunc: updateNumberOfGroups
        }
      ]
    }
  ]
}

export default config
