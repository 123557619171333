import React, { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Placeholder from '@components/placeholder'
import BasicTopBar from '@components/basicTopBar'
import { Body1, H3 } from '@toriihq/design-system'
import NotFoundImg from '@media/404.svg'
import CheckmarkImg from '@media/checkmark.svg'
import { getTaskApprovalRequestInfo } from '@selectors/instantAccess'
import { useParams } from 'react-router-dom'
import { submitTaskApprovalRequest } from '@root/store/actions/tasks'
import { TextAndIconBox } from './styles'
import { RouteParams } from './types'
import { getInstantAccessToken } from '@shared/utils'

const TaskApprovalRequestPage = (): ReactElement => {
  const dispatch = useDispatch()
  const { idOrg, idTask, response, idInstantAccessToken, secret } = useParams() as RouteParams

  const {
    success,
    authError,
    loading = true,
    approveFeedbackMessage,
    declineFeedbackMessage,
    alreadyCompleted
  } = useSelector(getTaskApprovalRequestInfo)

  const token = getInstantAccessToken(idInstantAccessToken, secret)
  const approved = response === 'approve'

  useEffect(() => {
    dispatch(submitTaskApprovalRequest({
      idOrg: Number(idOrg),
      idTask: Number(idTask),
      token,
      approved
    }))
  }, [dispatch, idOrg, idTask, token, approved])

  const renderTextAndIcon = (element: React.ReactNode, header: string, description?: string) => {
    return (
      <TextAndIconBox>
        {element}
        <H3>{header}</H3>
        {description && <Body1>{description}</Body1>}
      </TextAndIconBox>
    )
  }

  const renderAuthError = () => {
    return renderTextAndIcon(
      <img alt='Not found' src={NotFoundImg} width='110' />,
      'You don\'t have the required permissions'
    )
  }

  const renderThanksMessage = (isApproved: boolean, approveMessage?: string, declineMessage?: string) => {
    const approve = approveMessage || 'Your answer is Approve'
    const decline = declineMessage || 'Your answer is Decline'
    return renderTextAndIcon(
      <img alt='Success' src={CheckmarkImg} width='110' />,
      'Thanks!',
      isApproved ? approve : decline
    )
  }

  const renderDoneMessage = () => {
    return renderTextAndIcon(
      <img alt='Success' src={CheckmarkImg} width='110' />,
      'This request was already submitted, thanks!'
    )
  }

  const showThanksMessage = !authError && success
  const showDoneMessage = !authError && alreadyCompleted

  return (
    <>
      <BasicTopBar />
      <Placeholder loading={loading} type='text' rows={6} style={{ margin: '0 auto', paddingTop: '50px' }}>
        {authError && renderAuthError()}
        {showThanksMessage && renderThanksMessage(approved, approveFeedbackMessage, declineFeedbackMessage)}
        {showDoneMessage && renderDoneMessage()}
      </Placeholder>
    </>
  )
}

export default TaskApprovalRequestPage
