import { formFieldTypes } from '@root/constants'
import { WidgetConfig } from '@components/dashboard/widgets/types'
import { AGGREGATION_SORT_ORDERS, DynamicWidget } from '@reducers/dashboards/types'
import { widgetInfoSection } from '../../shared/widgetConfig/constants'
import { DropdownOptionsSource } from '../../types'
import { updateTableSortField, updateTableSortOrder } from '../../shared/widgetConfig/updateFuncs'

const config: WidgetConfig = {
  editableSections: [
    widgetInfoSection,
    {
      key: 'dataConfig',
      label: 'Data',
      fields: [
        {
          key: 'fields',
          label: 'Fields',
          type: formFieldTypes.columnsSelection,
          valuePathInWidget: 'displayConfig.fields'
        },
        {
          key: 'filters',
          label: 'Filter by',
          type: formFieldTypes.filters,
          valuePathInWidget: 'dataConfig.filters'
        },
        {
          key: 'sortBy',
          label: 'Sort by',
          type: formFieldTypes.inlineGroup,
          fields: [
            {
              key: 'sortField',
              type: formFieldTypes.dropdown,
              dropdownOptionsSource: DropdownOptionsSource.ENTITY_FIELDS,
              getValueFunc: (widget: DynamicWidget) => {
                return widget?.displayConfig?.sort?.[0]?.split(':')?.[0]
              },
              updateFunc: updateTableSortField
            },
            {
              key: 'sortOrder',
              type: formFieldTypes.buttonIcon,
              dropdownOptionsSource: DropdownOptionsSource.CLOSED_LIST,
              options: [
                { icon: 'SortAscending', value: AGGREGATION_SORT_ORDERS.ASC },
                { icon: 'SortDescending', value: AGGREGATION_SORT_ORDERS.DESC }
              ],
              getValueFunc: (widget: DynamicWidget) => {
                return widget?.displayConfig?.sort?.[0]?.split(':')?.[1] || 'asc'
              },
              updateFunc: updateTableSortOrder
            }
          ]
        }
      ]
    }
  ]
}

export default config
