import React, { ReactElement } from 'react'
import { AppIcon, Stack, Subtitle2 } from '@toriihq/design-system'
import {
  ContainerButton,
  OptionDescription,
  Label
} from './styles'

interface Props {
  label: string
  description?: string
  src?: string
  onChange?: () => void
  disabled?: boolean
}

export const SelectActionButton = ({
  label,
  description,
  disabled,
  src,
  onChange
}: Props): ReactElement => (
  <ContainerButton disabled={disabled} onClick={onChange}>
    <Stack direction='row' alignItems='center' gap='space-150'>
      {src && <AppIcon appName={label} appImageUrl={src} />}
      <OptionDescription>
        <Subtitle2><Label>{label}</Label></Subtitle2>
        {description && <Subtitle2 color='secondary'>{description}</Subtitle2>}
      </OptionDescription>
    </Stack>
  </ContainerButton>
)
