import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getWorkflowFormInfo, isLoadingWorkflowForm, isWorkflowFormAuthError, isWorkflowFormSubmitSuccess } from '@selectors/instantAccess'
import isEmpty from 'lodash/isEmpty'
import { getWorkflowFormInfoFromServer, submitWorkflowFormInfo } from '@actions/'
import { getInstantAccessToken } from '@shared/utils'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const paramsIdActionExe = ownProps.match.params.idActionExe
  const idActionExe = paramsIdActionExe && parseInt(paramsIdActionExe, 10)
  const { id, secret } = ownProps.match.params
  const isPreviewMode = !id || !secret

  const loading = isLoadingWorkflowForm(state)
  const submitSuccess = isWorkflowFormSubmitSuccess(state)
  const workflowFormInfo = isPreviewMode ? JSON.parse(window.localStorage?.getItem('formPreviewData') ?? '{}') : getWorkflowFormInfo(state)
  const isAuthError = isWorkflowFormAuthError(state)

  return {
    idOrg,
    idActionExe,
    id,
    secret,
    workflowFormInfo,
    isAuthError,
    submitSuccess,
    loading: loading && isEmpty(workflowFormInfo.formFields),
    isPreviewMode
  }
}

const mapDispatchToProps = {
  getWorkflowFormInfoFromServer,
  submitWorkflowFormInfo
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { idOrg, idActionExe, workflowFormInfo, id, secret } = stateProps
  const token = getInstantAccessToken(id, secret)

  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    getWorkflowFormInfoFromServer: dispatchProps.getWorkflowFormInfoFromServer.bind(null, { idOrg, idActionExe, token }),
    submitWorkflowFormInfo: ({ formFieldsValues, rejectForm }) => dispatchProps.submitWorkflowFormInfo({ idOrg, idActionExe, workflowFormInfo, token, formFieldsValues, rejectForm })
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(View))
