import styled from 'styled-components'

export const TooltipContainer = styled.div`
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.palette.border.primary};
  background-color: ${({ theme }) => theme.palette.background.primary};
  min-width: 236px;
`

export const DateContainer = styled.div`
  margin-bottom: 4px;
`

export const DateText = styled.span<{ $uppercase?: boolean }>`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.text.secondary};
  text-transform: ${({ $uppercase }) => $uppercase ? 'uppercase' : 'none'};
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  margin: 4px 0;
  color: ${({ theme }) => theme.palette.text.primary};
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`

export const SubHeader = styled.div`
  margin-bottom: 8px;
  white-space: pre-line;
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 12px;
  line-height: 16px;
`

export const KeyValueList = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.border.primary};
  padding-top: 8px;
  color: ${({ theme }) => theme.palette.text.secondary};
`

export const KeyValuePair = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;
  font-size: 12px;
  line-height: 16px;
`

export const Value = styled.span`
  font-weight: 600;
`
