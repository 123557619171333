import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import { withTheme } from 'styled-components'
import SourcesLine from '@components/dataSourcesBox/sourcesLine/index.jsx'
import { Subtitle2 } from '@toriihq/design-system'

const generateCSS = ({ theme }) => ({
  main: css({
    border: `1px solid ${theme.palette.border.primary}`,
    borderRadius: '8px'
  }),
  header: css({
    padding: '18px 20px'
  })
})

const SOURCES_LINE_TO_PROPS = [
  {
    name: 'Users',
    emptyListMessage: 'List of users is not provided by data sources'
  },
  {
    name: 'Usage',
    emptyListMessage: 'Usage is not provided by data sources'
  },
  {
    name: 'Expenses',
    emptyListMessage: <div>No expenses source was connected<br /> Please connect an expenses integration or upload an expenses file</div>
  },
  {
    name: 'Contracts',
    emptyListMessage: 'No contract uploaded'
  }
]

class DataSourcesBox extends React.Component {
  constructor (props) {
    super(props)
    const { theme } = props
    this.state = {
      CSS: generateCSS({ theme })
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.theme !== this.props.theme) {
      this.setState({
        CSS: generateCSS({ theme: this.props.theme })
      })
    }
  }

  render () {
    const { usersSources, usageSources, expensesSources, contractSources, appName, isSmallScreen } = this.props
    const dataSources = [usersSources, usageSources, expensesSources, contractSources]
    return (
      <div {...this.state.CSS.main}>
        <div {...this.state.CSS.header}>
          <Subtitle2>DATA SOURCES</Subtitle2>
        </div>
        {dataSources.map((dataSource, index) => {
          const { sources: sourcesList, disabledSource = {} } = dataSource
          return <SourcesLine
            key={SOURCES_LINE_TO_PROPS[index].name}
            sourcesList={sourcesList}
            disabledSource={disabledSource}
            appName={appName}
            {...SOURCES_LINE_TO_PROPS[index]}
            isSmallScreen={isSmallScreen}
          />
        })}
      </div>
    )
  }
}

DataSourcesBox.propTypes = {
  usersSources: PropTypes.object.isRequired,
  usageSources: PropTypes.object.isRequired,
  expensesSources: PropTypes.object.isRequired,
  contractSources: PropTypes.object.isRequired
}

DataSourcesBox.defaultProps = {
  usersSources: { sources: [] },
  usageSources: { sources: [] },
  expensesSources: { sources: [] },
  contractSources: { sources: [] }
}

export default withTheme(DataSourcesBox)
