import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.palette.border.primary};
  border-radius: 4px;
`

export const ChartsContainer = styled.div`
  padding: 18px 21px;
`

export const HeaderContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.primary};
  padding: 18px 21px;
  display: flex;
`

export const Header = styled.span`
  text-transform: uppercase;
  margin-right: 4px;
  line-height: 22px;
`

export const StyledLink = styled.span`
  margin-left: auto;
`
