import React from 'react'
import PropTypes from 'prop-types'
import { ExpandableSection, AppIcon, Subtitle2, Body2, Stack } from '@toriihq/design-system'
import OverflowTooltip from '@components/overflowTooltip'
import { getPermissionDescription } from '../../shared/thirdPartyPermissions'
import { permissionsLegend } from '../riskIcon'
import Analytics from '@helpers/analytics'
import RelativeTeamLink from '@components/relativeTeamLink'

class AppPermissionsBox extends React.Component {
  componentDidMount () {
    const { idOrg, getAppsPermissions } = this.props
    if (idOrg) {
      getAppsPermissions({ idOrg })
    }
  }

  componentDidUpdate (prevProps) {
    const { idOrg, idApp, getAppsPermissions } = this.props
    if (idOrg && prevProps.idOrg !== idOrg) {
      getAppsPermissions({ idOrg, idApp })
    }
  }

  renderPermissionList (permissions, idSource) {
    return (
      <Stack gap='space-100'>
        <Subtitle2>PERMISSIONS</Subtitle2>
        <Body2>
          {permissions.map((permission) => {
            const description = getPermissionDescription(idSource, permission)
            return (
              <div key={permission}>
                <OverflowTooltip
                  label={description}
                >
                  {description}
                </OverflowTooltip>
              </div>
            )
          }
          )}
        </Body2>
      </Stack>
    )
  }

  onToggle = (isOpen, component) => {
    if (isOpen) {
      Analytics.track('Clicked to view insight', {
        'Component': `Permissions list for ${component}`
      })
    }
  }

  render () {
    const { sourcesWithRiskLevels } = this.props
    return (
      sourcesWithRiskLevels.map((source) => (
        source.app.permissions?.length ? <ExpandableSection
          key={source.source.name}
          avatar={(
            <RelativeTeamLink to={`/app/${source.source.idApp}`}>
              <AppIcon appName={source.source.name} appImageUrl={source.source.imageUrl} hoverable />
            </RelativeTeamLink>
          )}
          title={`${source.app.name}'s ${source.source.name} permissions risk level is ${permissionsLegend[source.app.riskLevel].text}`}
          onToggle={isOpen => this.onToggle(isOpen, source.source.name)}
        >
          {this.renderPermissionList(source.app.permissions, source.source.id)}
        </ExpandableSection> : null
      ))
    )
  }
}

AppPermissionsBox.propTypes = {
  app: PropTypes.object.isRequired,
  sourcesWithRiskLevels: PropTypes.array.isRequired
}

export default AppPermissionsBox
