import { ReactElement, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getDashboardWidgetEditConfigDrawer } from '@selectors/ui'
import { DynamicWidget } from '@reducers/dashboards/types'
import { fieldTypes, formFieldTypes } from '@root/constants'
import EditWidgetForm from './editWidgetForm'
import { getWidgetTableKey, useGetWidgetFieldsDataByEntityType } from '../widgets/shared'
import { get, flattenDeep } from 'lodash'
import Analytics, { ENTITY_TYPE } from '@components/dashboard/analytics'
import { DashboardEditWidgetProps } from './types'
import { EditableSection, DropdownOptionsSource, EditableBaseFieldWithValue, EditableField, EditableBaseFieldWithValuePathInWidget, EntityField, FieldsDefaultValue } from '../widgets/types'
import { updateGeneralFieldByPath, useUpdateFilters } from '../widgets/shared/widgetConfig/updateFuncs'

const DashboardEditWidget = (props: DashboardEditWidgetProps): ReactElement | null => {
  const { dashboardName, dashboardSystemKey } = props

  const { sections, widget, onWidgetUpdate } = useSelector(getDashboardWidgetEditConfigDrawer)

  const [updatedWidget, setUpdatedWidget] = useState(widget)

  useEffect(() => {
    if (widget) {
      setUpdatedWidget(widget)
    }
  }, [widget])

  const widgetTableKey = getWidgetTableKey(widget)
  const updateFiltersFunc = useUpdateFilters(widgetTableKey)

  const allFormFields = flattenDeep(sections.map((section: EditableSection) => section.fields.map(field => get(field, 'fields', [field])))) as EditableField[]

  const onFieldChange = (fieldKey: string, fieldValue: any) => {
    Analytics.updateWidgetConfiguration({ dashboardName, dashboardSystemKey, widgetTitle: updatedWidget?.displayConfig.label, fieldName: fieldKey, entityType: ENTITY_TYPE.DRAWER })

    const fieldConfig = allFormFields.find((field: any) => field.key === fieldKey)

    if (!fieldConfig) {
      return
    }

    let newWidget = { ...updatedWidget }

    if (fieldConfig.type === formFieldTypes.filters) {
      newWidget = updateFiltersFunc({ widget: newWidget, filters: fieldValue })
    } else if (fieldConfig.updateFunc) {
      newWidget = fieldConfig.updateFunc({ widget: newWidget, fieldValue, entityFields: entityFields as EntityField[], fieldsDefaultValue: fieldsDefaultValue as FieldsDefaultValue })
    } else {
      newWidget = updateGeneralFieldByPath({ widget: newWidget, fieldValue, valuePathInWidget: (fieldConfig as EditableBaseFieldWithValuePathInWidget).valuePathInWidget })
    }

    setUpdatedWidget(newWidget)
    onWidgetUpdate({ updatedWidget: newWidget })
  }

  const { fieldsOptions: entityFields, filtersOptions, fieldOptionsValuesPerKey, fetchFieldValues } = useGetWidgetFieldsDataByEntityType(widget.entityType)

  const getFieldWithOptions = (field) => {
    if (field.type === formFieldTypes.inlineGroup) {
      const fields = field.fields.map(getFieldWithOptions)
      return { ...field, fields }
    }

    if (field.type === fieldTypes.dropdown) {
      let options = []

      switch (field.dropdownOptionsSource) {
        case DropdownOptionsSource.CLOSED_LIST:
          options = field.options
          break
        case DropdownOptionsSource.ENTITY_FIELDS:
          options = entityFields
          break
      }

      if (field.filterOptions) {
        options = field.filterOptions(options, widget)
      }

      return { ...field, options }
    }

    return field
  }

  const sectionWithFieldOptions = sections.map((section) => ({
    ...section,
    fields: section.fields.map(getFieldWithOptions)
  }))

  const fieldsDefaultValue = sectionWithFieldOptions.reduce((acc, section) => {
    section.fields.forEach((field) => {
      acc[field.key] = field.options?.[0]?.value
    })
    return acc
  }, {})

  const getFieldValue = (field: EditableBaseFieldWithValue, widget: DynamicWidget) => {
    if ('getValueFunc' in field) {
      return field.getValueFunc(widget)
    }

    if (field.valuePathInWidget) {
      const value = get(widget, field.valuePathInWidget)
      return value
    }

    return undefined
  }

  const generateInitialValues = (widget: DynamicWidget) => {
    return allFormFields.reduce((initialValues, field) => {
      const value = getFieldValue(field as EditableBaseFieldWithValue, widget)
      if (value !== undefined) {
        initialValues[field.key] = value
      }
      return initialValues
    }, {})
  }

  if (!widget.displayConfig) {
    return null
  } else {
    const initialFormValues = generateInitialValues(widget)

    return <EditWidgetForm
      widget={widget}
      sections={sectionWithFieldOptions}
      onFieldChange={onFieldChange}
      initialValues={initialFormValues}
      filtersOptions={filtersOptions}
      fieldOptionsValuesPerKey={fieldOptionsValuesPerKey}
      fetchFieldValues={fetchFieldValues}
    />
  }
}

export default DashboardEditWidget
